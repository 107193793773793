import { combineReducers } from 'redux';

import quoteReducer from './quoteReducer';
import darkModeReducer from './darkModeReducer'

const rootReducer = combineReducers({
  quoteData: quoteReducer,
  darkMode: darkModeReducer
});

export default rootReducer;
